import React from "react";
import './App.css';
import {Header} from './components/header';
import { useState , useEffect} from 'react';

function Pendingstagerequest(){

	const [stagerequests,setstagerequests] = useState([]);
	const [userid, setuserid] = useState("");
	const [userrole, setuserrole] = useState("");
	const [userdep, setuserdep] = useState("");
	const [morethanone, setmorethanone] = useState("true");

	let jwt = document.cookie.split('; ').reduce((prev, current) => {
		 const [name, ...value] = current.split('=');
		 prev[name] = value.join('=');
		 return prev;
	}, {});

	// gets some information from jwt
	function getUserInfo(){

        var data = {
            "action": "getuserinfo",
            "jwt": jwt.jwt
        }

        var dep = fetch("https://api.rs.digitalnatives.ch/",{

            method: "POST",
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
            },
            body: JSON.stringify(data)

        }).then(async (response)=>{

            let dep = await response.json().then((results)=>{

                setuserdep(results.key[0].fk_abteilung);
                setuserrole(results.key[0].fk_rolle);
                return results.key[0].fk_abteilung;

            });

            return dep;

        });

        return dep;

    }

    // gets stage requests
	async function getRequests(){

		// administrators are allowed to see any requests
		if(userrole >= 4){

			var data = {

				"action": "getstagereqs",
				"jwt": jwt.jwt

			}

		}
		// department leaders are just allowed to see the requests of their own dep
		else {

			var data = {
				"action": "getstagereqs",
				"id_dep": await getUserInfo(),
				"jwt": jwt.jwt
			}
		}

		var role = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then(async(response)=>{

			// there is trouble with mapping an empty array, so we need a function to bypass this issue
			if(response.status != 200){setmorethanone("false");}

			let roleresult = response.json().then((results)=>{

				console.log(results.key)
				setstagerequests(results.key);

			});

		});

	}

	// sends the answer, may take multiple times because react is crap
	function answerStageReq(stage, answer, whoasked){

		var data = {

			"action": "answerstagereq",
			"stage_id": stage,
			"answer": answer,
			"id_user": whoasked,
			"jwt": jwt.jwt

		}

		var role = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then((results)=>{

				console.log(results.key);

			});

		});

	}

	// execute on load
	useEffect(()=>{getUserInfo(); getRequests();}, []);

	// jsx
	return(
		<div className="add-xyz">
			
		<h1>Offene Stageanfragen</h1>
			<div className="tableapi">
			<table rules="all">
			  	<tr>
					<td><b>Benutzer</b></td>
					<td><b>Stage-Nr.</b></td>
					<td><b>Status RCH</b></td>
					<td><b>Status Abteilung</b></td>
					<td><b>Anfrage annehmen</b></td>
					<td><b>Anfrage ablehnen</b></td>
				</tr>
			  	<tbody>
				{
					morethanone == "true" ? 
					stagerequests.map((stage)=>(
						<tr>
							<td>{stage.vorname} {stage.nachname}</td>
							<td>{stage.fk_stage}</td>
							<td>{stage.status_rch == "Angenommen" ? <yes>{stage.status_rch}</yes> : stage.status_rch == "Abgelehnt" ? <no>{stage.status_rch}</no> : stage.status_rch}</td>
							<td>{stage.status_abt == "Angenommen" ? <yes>{stage.status_abt}</yes> : stage.status_abt == "Abgelehnt" ? <no>{stage.status_abt}</no> : stage.status_abt}</td>

							<td><button onClick={async () => {
								await getRequests(); 
								await answerStageReq(stage.fk_stage, "Angenommen", stage.fk_user); 
								window.location = "/user/offenestageanfragen";
							}}>Annehmen</button></td>

							<td><button onClick={async () => {
								await getRequests(); 
								await answerStageReq(stage.fk_stage, "Abgelehnt", stage.fk_user); 
								window.location = "/user/offenestageanfragen";
							}}>Ablehnen</button></td>
							
						</tr>)
					  )
					: [<tr rowspan="100"><td colspan="6">Es gibt noch keine Anfragen</td></tr>]
				}
			  </tbody>
			</table>
			</div>

			
			
		</div>
	  
	)
}

export default Pendingstagerequest;
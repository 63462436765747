import React from 'react';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';

export function Home() {

	return(


		<div className="mainsite">
		<div className="banner"><img className="bannerimg"/></div>
		<div className="bannertitle">

			<span><a href="https://www.raiffeisen.ch">Raiffeisen</a> <li className="li">Stagen</li> </span>
			<h1>Hochschulabsolventen</h1>

		</div>

			<div id="stage-home" className="section">

				<h1>Dein Karrierestart als Trainee bei einer Raiffeisenbank</h1>
				<p>Das Trainee-Programm bietet Hochschulabsolventen mit Fach- und Führungspotenzial eine ideale Ausgangslage für den Start in die berufliche Karriere. Du bist von Beginn an in das Projekt- und Banktagesgeschäft involviert. Dabei wirst du von einem Fachbetreuer in den Abteilungen und einem Trainee-Verantwortlichen begleitet.</p>				 
					
			</div>

		</div>

	)

}

export default Home;

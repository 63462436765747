// import necessary files
import React,{useState, useEffect} from "react";
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import './user/App.css';
import './public/App.css';

// components
import {Header} from './user/components/header';
import {Navsidebar} from './user/components/navsidebar';
import {Publicheader} from './public/components/Header';

// public sites
import {Home} from './public/sites/Home';
import {Contact} from './public/sites/Contact';
// import {Register} from './public/sites/Register'; // neither necessary nor functional (yet)
import {Login} from './public/sites/Login';
import {NF404} from './public/sites/NF404';

// user specific sites
import Userhome from './user/home';
import Addabteilung from './user/addabteilung';
import EditAbteilung from './user/editabteilung';
import Addbenutzer from './user/addbenutzer';
import Planner from './user/planner';
import Stageerstellen from './user/stageanerstellen';
import EditBenutzer from './user/editbenutzer';
import Pendingstagerequest from './user/stageanfragenadmin';
import Offeneanfragen from './user/useranfragen';
import Planner2 from './user/plannertry2';
import Editstage from './user/editstage';
import Loginfirst from './user/loginprompt';

// the actual app function
function App() {

	// sets login status
	const [loggedout, setloggedout] = useState(0);
	const [userrole, setuserrole] = useState("");

	// what to do on logout or cookie expiration
	function clearJWT(){

		document.cookie = 'jwt=; path=/user; max-age=0';
		document.cookie = 'jwt=; path=/; max-age=0';
		setloggedout(1);

	}

	// gets the current users permission role
	async function getUserRole(){

		// gets jwt from cookie
		let jwt = document.cookie.split('; ').reduce((prev, current) => {
			const [name, ...value] = current.split('=');
			prev[name] = value.join('=');
			return prev;
		}, {});

		// requests the role of the jwt's user
		var data = {

			"action": "getuserrole",
			"jwt": jwt.jwt

		}

		var role = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then(async(response)=>{

			// if the response isnt 200, theres an error with getting the users role, forcing a logout
			if(response.status != 200){

				clearJWT();
				return false;

			}

			let roleresult = response.json().then((results)=>{

				setuserrole(results.key);
				return results.key;

			});

			return await roleresult;

		});

		return role;

	}

	// write the variable into the usestate and check for login every 10 seconds
	getUserRole().then(role => setuserrole(role));
	useEffect(() => {

		const interval = setInterval(() => {

			getUserRole().then(role => setuserrole(role));

		}, 10000);

		return () => clearInterval(interval);

	}, []);

	// what to show in the gui if the user is logged out
	if(loggedout == 1){

		return (

			<div className="App">

				<Publicheader />
				<Routes>

					<Route path="/" element={<Home />} />
					<Route path="/home" element={<Home />} />
					<Route path="/login" element={<Login />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/user/*" element={<Loginfirst />} />
					<Route path="*" element={<NF404 />} />

				</Routes>

				<div id="menuwindow">

					<div id="linksinmenu">

						<Link to="/">Home</Link><br />
						<Link to="/login">Einloggen</Link><br />
						<Link to="/contact">Kontakt</Link>

					</div>

				</div>

			</div>

		);

	}

	// if the user is logged in
	else {

		// different options for different roles, the roles are documented in "../../Rollen und Berechtigungen.md"
		if(userrole >= 4){

			return (

				<div className="App">

					<Header />
					<Navsidebar />

					<Routes>

						<Route path="/" element={<Home />} />
						<Route path="/home" element={<Home />} />
						<Route path="/user/" element={<Userhome />} />
						<Route path="/user/home" element={<Userhome />} />
						<Route path="/user/addabteilung" element={<Addabteilung/>} />
						<Route path="/user/editabteilung" element={<EditAbteilung/>} />
						<Route path="/user/addbenutzer" element={<Addbenutzer/>} />
						<Route path="/user/editbenutzer" element={<EditBenutzer/>} />
						<Route path="/user/planner" element={<Planner/>} />
						<Route path="/user/stageerstellen" element={<Stageerstellen/>} />
						<Route path="/user/offenestageanfragen" element={<Pendingstagerequest/>} />
						<Route path="/user/meineanfragen" element={<Offeneanfragen/>} />
						<Route path="/user/planner2" element={<Planner2/>} />
						<Route path="/user/stageverwalten" element={<Editstage/>} />
						<Route path="/user/*" element={<Loginfirst />} />
						<Route path="*" element={<NF404 />} />

					</Routes>

				</div>

			);

		}
		else if(userrole == 3){

			return (

				<div className="App">

					<Header />
					<Navsidebar />
					<Routes>

						<Route path="/" element={<Home />} />
						<Route path="/home" element={<Home />} />
						<Route path="/login" element={<Login />} />
						<Route path="/user/" element={<Userhome />} />
						<Route path="/user/home" element={<Userhome />} />
						<Route path="/user/planner" element={<Planner/>} />
						<Route path="/user/stageerstellen" element={<Stageerstellen/>} />
						<Route path="/user/offenestageanfragen" element={<Pendingstagerequest/>} />
						<Route path="/user/stageverwalten" element={<Editstage/>} />
						<Route path="/user/*" element={<Loginfirst />} />
						<Route path="*" element={<NF404 />} />

					</Routes>

				</div>

			);

		}
		else if(userrole == 2){

			return (

				<div className="App">

					<Header />
					<Navsidebar />
					<Routes>

						<Route path="/" element={<Home />} />
						<Route path="/home" element={<Home />} />
						<Route path="/login" element={<Login />} />
						<Route path="/user/" element={<Userhome />} />
						<Route path="/user/home" element={<Userhome />} />
						<Route path="/user/meineanfragen" element={<Offeneanfragen/>} />
						<Route path="/user/planner" element={<Planner2/>} />
						<Route path="/user/*" element={<Loginfirst />} />
						<Route path="*" element={<NF404 />} />

					</Routes>

				</div>

			);

		}
		else if(userrole == 1){

			return (

				<div className="App">

					<Header />
					<Navsidebar />
					<Routes>

						<Route path="/" element={<Home />} />
						<Route path="/home" element={<Home />} />
						<Route path="/login" element={<Login />} />
						<Route path="/user/" element={<Userhome />} />
						<Route path="/user/home" element={<Userhome />} />
						<Route path="/user/meineanfragen" element={<Offeneanfragen/>} />
						<Route path="/user/planner" element={<Planner2/>} />
						<Route path="/user/*" element={<Loginfirst />} />
						<Route path="*" element={<NF404 />} />

					</Routes>

				</div>
			);

		}

	}

}

export default App;
// imports
import React from "react";
import './App.css';
import {Header} from './components/header';
import { useState , useEffect} from 'react';
import { data } from "autoprefixer";
import { SketchPicker } from "react-color";

// main function
function Editstage(){

	// set variables
	const [stage, setstage]=useState("");
	const [title, settitle]=useState([]);
	const [verantwortlicher, setverantwortlicher]=useState("");
	const [startdate, setstartdate]=useState("");
	const [enddate, setenddate]=useState("");
	const [aktiv, setaktiv]=useState("1");
	const [depid, setdepid]=useState("");
	const [offenestellen, setoffenestellen]=useState("");
	const [description, setdescription]=useState("");
	const [seldepname, setseldepname]=useState("");
	const [seldepid, setseldepid]=useState("");
	const [departments,setDepartments]=useState([]);
	const [bgcolor, setbgcolor] = useState("#fff");

	//validation
	const [submitted, setSubmitted]=useState("");
	const [valid, setValid]=useState("");
	const [reply, setReply]=useState("");
	const [status, setStatus]=useState("");
	const [submitted2, setSubmitted2]=useState("");
	const [valid2, setValid2]=useState("");
	const [morethanone, setmorethanone] = useState("true");

	// check, if a specific stage is passed in the url
	var url = new URL(window.location.href);
	var passedstage = url.searchParams.get("id");
	const [selstage, setselstage] = useState(passedstage);

	// jwt
	let jwt = document.cookie.split('; ').reduce((prev, current) => {
		const [name, ...value] = current.split('=');
		prev[name] = value.join('=');
		return prev;
	}, {});

	// what to do if the color
	function handlecolor(){document.getElementById('color-clicked').classList.toggle('active');}

	// edits the stage
	async function editData(){

		// resets validation
		setValid("");
		setReply("");
		setStatus("");
		setSubmitted(true);
		setSubmitted2(false);

		if(startdate && enddate && aktiv && offenestellen && description){

			setValid(true);

			let data = {

				"action": "editstage",
				"stage_id": selstage,
				"startdate": startdate,
				"enddate": enddate,
				"openspots": offenestellen,
				"active": aktiv,
				"description": description,
				"bgcolor": bgcolor,
				"jwt": jwt.jwt

			}


			fetch("https://api.rs.digitalnatives.ch/",{

				method: "POST",
				headers:{
					'Accept':'application/json',
				},
				'Content-Type':'application/json',
				body: JSON.stringify(data)

			}).then((resp)=>{

				resp.json().then((results)=>{

					setReply(results.key);
					setStatus(results.status);

				})

			})

		}
		else {setValid(false)}

	}

	// get the name of a department for an id
	function getDepName(selecteddepid){

		var data = {

			"action": "getdepname",
			"depid": selecteddepid,
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then((results)=>{

				setseldepname(results.key[0].abteilungsname);

			});

		});

	}

	// gets the department id for a name
	function getDepID(){

		var data = {

			"action": "getdepid",
			"depname": seldepname,
			"jwt": jwt.jwt

		}

		let depid = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			let depid = response.json().then((results)=>{

				try {

					let depid = results.key[0].id_abteilung;
					return depid;

				}
				catch {return false;}

			});

			return depid;

		});

		return depid;
	}

	// get all departments
	function getDeps(){

		var data = {

			"action": "getdeps",
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then((results)=>{

				setDepartments(results.key);

			});

		});

	}

	// get information to a stage
	function getstageInfo(stage_id){

		var data = {

			"action": "getstageinfo",
			"stage_id": stage_id,
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then((results)=>{

				setstartdate(results.key[0].start);
				setenddate(results.key[0].ende);
				setseldepname(results.key[0].abteilungsname);
				setdescription(results.key[0].stagebeschreibung);
				setaktiv(results.key[0].aktiv);
				setoffenestellen(results.key[0].offenestellen);
				setbgcolor(results.key[0].farbe);
				console.log(seldepname);

			});

		});

	}

	// get all stages
	function getStages(){

		var data = {

			"action": "getstages",
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			if(response.status != 200){

				setmorethanone("false");

			}

			response.json().then((results)=>{

				settitle(results.key);

			});

		});

	}

	// resets data
	function resetFields(){

		setselstage("");
		setstartdate("");
		setenddate("");
		setseldepname("");
		setoffenestellen("");
		setdescription("");

	}

	// execute on load
	useEffect(() => {

		getStages();
		getDeps();
		getstageInfo(selstage);
		getDepName(depid);

	}, []);

	// jsx
	return(

		<div className="add-xyz">
			<div className="top-content">
				<h1>Stage bearbeiten</h1>
			</div>
			<div className="contentform">
				<form>
					<label>Stage auswählen</label><br></br>
					<select onClick={getStages} value={selstage} onChange={(e)=>{setselstage(e.target.value); getstageInfo(e.target.value); getDeps();} }>
						<option value="" disabled selected> - Stage auswählen - </option>
							{
								morethanone == "true" ?
								title.map((item)=>
								<option value={item.id_stage} onChange={(e)=>{settitle(e.target.value)} }>{item.title}</option>
								) :
								<span>Keine Stagen vorhanden</span>
							}
					</select><br></br>

					<label>Startdatum</label><br></br>
					<input type="date" name="startdate"value={startdate} onChange={(e)=>{setstartdate(e.target.value)} }/><br></br>
					{ submitted &! startdate &! reply ? <span className="not-so-great-success">Wählen Sie bitte ein Startdatum</span> : null }<br></br>
					<label>Enddatum</label><br></br>
					<input type="date" name="enddate"value={enddate} onChange={(e)=>{setenddate(e.target.value)} }/><br></br>
					{ submitted &! enddate &! reply ? <span className="not-so-great-success">Wählen Sie bitte ein Enddatum</span> : null }<br></br>
					<select value={aktiv} onChange={(e)=>{setaktiv(e.target.value)}}>
							<option value={1}selected>Aktiv</option>
							<option value={0}>Inaktiv</option>
					</select><br></br>
					<input type="number" min="0" name="offenestellen" value={offenestellen} onChange={(e)=>{setoffenestellen(Math.abs(e.target.value))} }placeholder="Offene stellen"/><br></br>
					{ submitted &! offenestellen &! reply ? <span className="not-so-great-success">Wählen Sie bitte die Anzahl offener Stellen</span> : null }<br></br>

					<div className="sketchpicker">
						Farbe (Helle Farben vermeiden, da der Text unsichtbar wird)
						<br />
						<br />

						<div onClick={handlecolor} className="circle" style={{background: bgcolor}}></div>


						<div id="color-clicked" className="color-clicked">

							<SketchPicker
							disableAlpha={true}
							onChange={(color) => {
								setbgcolor(color.hex);
							}}
							color={bgcolor}
							/>

						</div>
					</div>

					<br />

					<textarea type="text" value={description} onChange={(e)=>{setdescription(e.target.value)}} placeholder="Beschreibung"></textarea><br></br>
					{ submitted &! description &! reply ? <span className="not-so-great-success">Wählen Sie bitte eine Stagebeschreibung</span> : null }<br></br>
					<button type="button" onClick={()=>{editData();resetFields();}}>Bearbeiten</button>
					<br />
					<br />
					{ submitted && valid && (status == 400) ? <span className="not-so-great-success">{reply}</span> : null }
					{ submitted && valid && (status == 200) ?<span className='great-success'>{reply}</span> : null }

				 <br />
				 <br />
				</form>

			</div>


		</div>

	)

}

export default Editstage;

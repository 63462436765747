// import necessary files
import './App.css';
import {Header} from './components/header';
import React,{ useEffect, useState} from "react";
import {Button, Box, TextField} from '@material-ui/core';
import {Field, Form, Formik} from 'formik';
import {object, string} from 'yup';

// actual site
function Addabteilung(){

	// set variables
	const [depname, setdepname]=useState("");
	const [submitted, setSubmitted]=useState("");
	const [valid, setValid]=useState("");
	const [reply, setReply]=useState("");

	// gets jwt
	let jwt = document.cookie.split('; ').reduce((prev, current) => {
		const [name, ...value] = current.split('=');
		prev[name] = value.join('=');
		return prev;
	}, {});

	// sends a request to create a new department
	function saveData(){

		// sets the submit status for the error / success message
		setSubmitted(true);

		// if a name is provided, request 
		if(depname){

			setValid(true);
			let data={

				"action": "newdep",
				"depname": depname,
				"jwt": jwt.jwt

			}

			console.warn(data)
			fetch("https://api.rs.digitalnatives.ch/",{
				method: "POST",
				headers:{
					'Accept':'application/json',
					'Content-Type':'application/json',
				},
				body: JSON.stringify(data)
				}).then((resp)=>{
				resp.json().then((results)=>{
					setReply(results.key);
					console.warn(results);
				})
			})
		}
		else {setValid(false);}
	}
	
	// reset the field
	function resetFields(){setdepname("");}

	// jsx
	return(

		<div className="add-xyz">
			<div className='top-content'>
				<h1>Neue Abteilung hinzufügen</h1>
				<span>Falls keine Bestätigung auftaucht, versuche es bitte erneut.</span>
				<br />
			</div>
			<div className='contentform'>
				<form onSubmit={e => {e.preventDefault(); saveData(); resetFields();}}>
					<input type="text" name="name" value={depname} onChange={(e)=>{setdepname(e.target.value)}} placeholder="Abteilungsname" maxLength="60" />
					<br />
					{ submitted &! valid ? <span className="not-so-great-success">Sie müssen einen Abteilungsnamen eingeben</span> : null }
					{ submitted && valid &! (reply == "Something went wrong, does the department already exist?")? <span className="great-success"> {reply} </span> : null }
					{ submitted && valid && (reply == "Something went wrong, does the department already exist?")? <span className="not-so-great-success"> {reply} </span> : null }
					<br /><br />
					<button	type="button" onClick={()=>{saveData(); resetFields();}}>Hinzufügen</button>
				</form>
			</div>
		</div>

	)
}

export default Addabteilung;
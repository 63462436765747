import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';

export function NF404() {

	//jsx
	return(

		<div>

			<div className="bg3" />

			<div className="notfound">

				<div className="notfoundbubble">404. <br /> <Link to="/">Go <u>Home</u></Link></div>

			</div>

		</div>

	)


}

export default NF404;

// imports
import React, { useState, useEffect, createRef } from "react";
import './App.css';
import FullCalendar ,{EventContentArg} from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import localtestevents from './fixtures/events.json';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Button, Box, TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

// main function
function Planner2(){

	// set vars
	const [title, settitle]=useState("");
	const [startdate, setstartdate]=useState("");
	const [enddate, setenddate]=useState("");
	const [description, setdescription]=useState("");
	const [user, setuser] = useState("");
	const [reply, setReply] = useState("");
	const [requested, setrequested] = useState(false);
	const [status, setStatus] = useState("");

	// jwt
	let jwt = document.cookie.split('; ').reduce((prev, current) => {
		const [name, ...value] = current.split('=');
		prev[name] = value.join('=');
		return prev;
	}, {});

	const calenderRef = createRef();

	// requests a stage
	function requestStage(stage){

		let data = {

			"action": "newstagereq",
			"fk_stage": stage,
			"fk_user": user,
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((resp)=>{

			resp.json().then((results)=>{

				setReply(results.key);
				setStatus(results.status);

			});

		});

	}

	// sends a request to the stage
	function sendRequest(stage){

		let data = {

			"action": "getuserinfo",
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers: {
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((resp)=>{

			resp.json().then((results)=>{

				setuser(results.key[0].id_user);

			});

		});

		requestStage(stage);

	}

	// executes the function without arguments, so reactjs doesnt bug around
	useEffect(()=>{sendRequest();}, []);

	// what to do if an event is clicked
	const handleEventClick = async(clickInfo)=>{

		var stageid = clickInfo.event.id;

		// pop up
		confirmAlert({

			customUI: ({ onClose }) => {

				let title = ` ${clickInfo.event.title}`;
				let description = ` ${clickInfo.event.extendedProps.description}`;
				let start = ` ${clickInfo.event.start}.`;
				let momstart = moment(start).format('DD.MM.YYYY');
				let end = ` ${clickInfo.event.end}`;
				let momend = moment(end).format('DD.MM.YYYY');
				let abteilung = ` ${clickInfo.event.extendedProps.abteilung}`;
				let openspots = ` ${clickInfo.event.extendedProps.offenestellen}`;

				// pop up jsx
				return (

				  <div className='custom-ui'>
					<div className="popupconfirm-title">
						<h1>{title}</h1>
						<p>Details für {title}</p><br></br>

						<p><b>Starttermin:</b> {momstart}</p>
						<p><b>Endtermin:</b> {momend}</p>
						<p><b>Abteilung:</b> {abteilung}</p>
						<p><b>Offene Stellen:</b> {openspots}</p>
						<p><b>Beschreibung:</b> {description}</p>
						<br></br>


					</div>
					<div className="popupconfirm-buttons">
						{openspots != 0 ? <button id="request" onClick={() => {setrequested(true); sendRequest(stageid); onClose();}}>Anfordern</button> : null}


						<button id="cancel" onClick={onClose}>Abbrechen</button>
					</div>
				  </div>

				);

			}

		});

	}

	// jsx
	return(
		<div className="add-calendar">

			<div className="calendar">
				<FullCalendar
					ref={calenderRef}
					plugins={[ dayGridPlugin, timeGridPlugin,listPlugin]}
					initialView="dayGridMonth"
					editable="false"
					selectable="false"
					deselectable="false"
					eventStartEditable="false"
					events="https://api.rs.digitalnatives.ch/stagescalendar.php"
					eventClick={handleEventClick}

					customButtons={{
						myTimeDayBtn:{
							text: "timeDay",
							click() {
								const calender = calenderRef.current;

								if (calender) {
									const calenderApi = calender.getApi();

									calenderApi.changeView("timeGridDay")
								}
							},
						},

						myTimeWeekBtn:{
							text: "timeWeek",
							click() {
								const calender = calenderRef.current;

								if (calender) {
									const calenderApi = calender.getApi();

									calenderApi.changeView("timeGridWeek");
								}
							},
						},
					}}

					headerToolbar={{
						left: "prev,next today",
						center: "title",
						right: "dayGridDay,dayGridWeek,dayGridMonth,listYear"
					}}
				/>
			</div>
		</div>

	)

}

export default Planner2;
// imports
import React from "react";
import './App.css';
import {Header} from './components/header';
import { useState , useEffect} from 'react';

// main function
function Useranfragen(){

	// set vars
	const [stagerequests,setstagerequests] = useState([]);
	const [userid, setuserid] = useState("");
	const [morethanone, setmorethanone] = useState("true");

	// jwt
	let jwt = document.cookie.split('; ').reduce((prev, current) => {
		 const [name, ...value] = current.split('=');
		 prev[name] = value.join('=');
		 return prev;
	}, {});

	// gets all own requests
	function getRequests(){

		var data = {

			"action": "getuserstagereqs",
			"jwt": jwt.jwt

		}

		var role = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then(async(response)=>{

			if(response.status != 200){setmorethanone("false");}

			let roleresult = response.json().then((results)=>{

				setstagerequests(results.key);

			});

		});

	}

	// gets some information for a user
	function getUserInfo(){

		var data = {

			"action": "getuserinfo",
			"jwt": jwt.jwt

		}

		var role = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then((results)=>{

				setuserid(results.key[0].id_user);

			});

		});

	}

	// removes a request
	function removeRequest(stage){

		var data = {

			"action": "remstagereq",
			"fk_user": userid,
			"fk_stage": stage,
			"jwt": jwt.jwt

		}

		var role = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then((results)=>{

				setstagerequests(results.key);

			});

		});

	}

	// execute on load
	useEffect(()=>{getUserInfo(); getRequests();},[]);

	// jsx
	return(
		<div className="add-xyz">
		   
		<h1>Meine Stageanfragen</h1>
		   <div className="tableapi">
		   <table rules="all">
			  <tr>
				   <td><b>Stage-Nr.</b></td>
				   <td><b>Status RCH</b></td>
				   <td><b>Status Abteilung</b></td>
				   <td><b>Anfrage zurückziehen</b></td>
			   </tr>
			  <tbody>
			   {
				   morethanone == "true" ? 
				   stagerequests.map((stage)=>(
					   <tr>
						   <td>{stage.fk_stage}</td>
						   <td>{stage.status_rch == "Angenommen" ? <yes>{stage.status_rch}</yes> : stage.status_rch == "Abgelehnt" ? <no>{stage.status_rch}</no> : stage.status_rch}</td>
						   <td>{stage.status_abt == "Angenommen" ? <yes>{stage.status_abt}</yes> : stage.status_abt == "Abgelehnt" ? <no>{stage.status_abt}</no> : stage.status_abt}</td>
						   <td><button onClick={()=>{removeRequest(stage.fk_stage); removeRequest(stage.fk_stage); removeRequest(stage.fk_stage); removeRequest(stage.fk_stage); window.location = "/user/meineanfragen";}}>Zurückziehen</button></td>
						   
					   </tr>)
					  )
					: <td colspan="4">Es gibt noch keine Anfragen</td>
			   }
			  </tbody>
		   </table>
		   </div>

			
			
		</div>
	  
	)
}

export default Useranfragen;


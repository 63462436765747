import React from 'react';

export function Contact() {
	
	//  
	return(

		<div>   
		<div className="bg4"></div>

			<div id="contactform">
				
				<h1>Kontakt</h1>
				<h2 id="letusknow">
				Möchtest Du mit uns in Kontakt treten? Lass es uns wissen.<br />
				
				</h2>
				<div id="formdiv">
					<a href="mailto:tamara.krizanovic@raiffeisen.ch"><h2>Kontakt per E-Mail (tamara.krizanovic@raiffeisen.ch)</h2></a>
					<a href="tel:+41714241733"><h2>Kontakt per Telefon (+41 71 424 17 33)</h2></a>
				</div>



			</div>

		</div>

	)

}

export default Contact;
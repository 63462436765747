// imports
import './App.css';
import React,{ useEffect, useState} from "react";

// main function
function EditAbteilung(){

	// vars
	const [edit, setEdit] = useState("");
	const [depname, setdepname] = useState("");
	const [seldepname, setseldepname] = useState("");
	const [seldepname2, setseldepname2] = useState("");
	const [newabteilung, newsetabteilung] = useState("");
	const [departments,setDepartments] = useState([]);
	const [del, setDel] = useState("");

	//validation
	const [submitted, setSubmitted] = useState("");
	const [valid, setValid] = useState("");
	const [reply, setReply] = useState("");
	const [status, setStatus] = useState("");
	const [submitted2, setSubmitted2] = useState("");
	const [valid2, setValid2] = useState("");

	// jwt
	let jwt = document.cookie.split('; ').reduce((prev, current) => {
		const [name, ...value] = current.split('=');
		prev[name] = value.join('=');
		return prev;
	}, {});

	// edits data
	function editData(){

		// reset validation
		setValid("");
		setReply("");
		setStatus("");
		setSubmitted(true);
		setSubmitted2(false);

		// check for validation and fetch
		if(seldepname && edit){

			setValid(true);
			let data = {

				"action": "editdep",
				"jwt": jwt.jwt,
				"depname": seldepname,
				"editeddepname": edit

			}

			fetch("https://api.rs.digitalnatives.ch/",{

				method: "POST",
				headers:{
					'Accept':'application/json',
				},
				'Content-Type':'application/json',
				body: JSON.stringify(data)

			}).then((resp)=>{

				resp.json().then((results)=>{

					setReply(results.key);
					setStatus(results.status);

				});

			});

			if(status == 200){resetFields();}

		}
		else {setValid(false);}

	}

	// delete a department
	function delData(){

		// reset validation
		setValid2("");
		setSubmitted2(true);
		setSubmitted(false);
		setStatus("");

		// check for validation and fetch
		if(seldepname2){

			setValid2(true);
			let data = {

				"action": "remdep",
				"depname": seldepname2,
				"jwt": jwt.jwt

			}

			fetch("https://api.rs.digitalnatives.ch/",{

				method: "POST",
				headers:{
					'Accept':'application/json',
					'Content-Type':'application/json',
				},
				body: JSON.stringify(data)

			}).then((resp)=>{

				resp.json().then((results)=>{

					setReply(results.key);
					setStatus(results.status);

				});

			});

		}

	}

	// get all departments
	function getDeps(){

		var data = {

			"action": "getdeps",
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then((results)=>{

				setDepartments(results.key);

			});

		});

	}

	// reset data
	function resetFields(){

		setEdit("");
		setseldepname2("");
		setseldepname("");

	}

	// execute function on launch
	useEffect(()=>{getDeps();}, []);

	// jsx ("i gave up formatting his mess" - Noah)
	return(

		<div className="add-xyz">

			<div className='top-content'><h1>Abteilung bearbeiten</h1></div>

			<div className='contentform'>

				<form>

					<select onClick={getDeps} value={seldepname} onChange={(e)=>{setseldepname(e.target.value); setEdit(e.target.value)} }>
						
						<option value="" disabled selected> - Abteilung auswählen - </option>
						{ departments.map( (item)=> <option value={item.abteilungsname} onChange={(e)=>{setDepartments(e.target.value)} }>{item.abteilungsname}</option> ) }

					</select><br />

					{ submitted &! seldepname &! reply ? <span className="not-so-great-success">Wählen Sie bitte eine Rolle aus</span> : null }
						<br /><br />
					<input type="text" name="abteilung" value={edit} onChange={(e)=>{setEdit(e.target.value)} } placeholder="Neuer Abteilungsname" required="true"/>
						<br></br>
							{ submitted &! edit &! reply ? <span className="not-so-great-success">Wählen Sie bitte eine Rolle aus</span> : null }
						<br></br><br></br>

					<button type="button" onClick={()=>{editData();}}>Bearbeiten</button>
					<br></br>
						{ submitted && valid && (reply == "What were you even trying to do, cousin?") ? <span className="not-so-great-success">{reply}</span> : null }
						{ submitted && valid &! (reply == "What were you even trying to do, cousin?") ? <span className="great-success">{reply}</span> : null }
					<br></br>

				</form>

			</div>
			<div className="top-content"><h1>Abteilung löschen</h1></div>
			<div className='contentform'>
				<form>
				<select onClick={getDeps} value={seldepname2} onChange={(e)=>{setseldepname2(e.target.value)} }>
				<option value="" disabled selected> - Abteilung auswählen - </option>
				{
					departments.map((item)=>
					<option value={item.abteilungsname} onChange={(e)=>{setDepartments(e.target.value)} }>{item.abteilungsname}</option>
					)
				}
				</select><br></br>
				{ submitted2 &! seldepname2 &! reply ? <span className="not-so-great-success">Wählen Sie bitte eine Rolle aus</span> : null }
				<br></br><br></br>
				<button type="button" onClick={()=>{delData(); getDeps(); setseldepname2("")}}>Löschen</button><br></br>
				{ submitted2 && valid2 && (status == 400) ? <span className="not-so-great-success">{reply}</span> : null }
				{ submitted2 && valid2 && (status == 200) ?<span className='great-success'>{reply}</span> : null }
				{ submitted && valid2 && (reply == "What were you even trying to do, cousin?") ? <span className="not-so-great-success">{reply}</span> : null }
				{ submitted && valid2 &! (reply == "What were you even trying to do, cousin?") ? <span className="great-success">{reply}</span> : null }
				</form>
			</div>
		</div>

	)
}

export default EditAbteilung;
import logo from '../res/logo.png';
import menu from '../res/menu.png';
import '../App.css';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';

export class Publicheader extends Component {

	render(){

		function handlemenu(){
			document.getElementById('menuwindow').classList.toggle('active');
			document.getElementById('menunav').classList.toggle('menu-open');
			reset_animation();
			document.getElementById('menunav').classList.toggle('menu-closed');
		}

		function reset_animation() {
		 	var el = document.getElementById('menunav');
		 	el.style.animation = 'none';
		 	el.offsetHeight; /* eslint-disable-line */
		 	el.style.animation = null; 
		}


		return (

			<nav className="nav">

				<Link to="/" id="logonava"><img src={logo} id="logonav" /></Link>

				<div className="links">
					<Link to="/">Home</Link>
					<Link to="/login">Login</Link>
					<Link to="/contact">Contact</Link>
				</div>

				<img onClick={handlemenu} className="menu-closed" id="menunav" />

			</nav>
		
		);

	}
	
}
import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import menu from '../img/menu.png';
import raiffeisen from '../img/Raiffeisenlogo.png';

export class Header extends Component {

    render(){

        function handleclose(){
            document.getElementById('yeetfield').classList.toggle('active');
            document.getElementById('sidenav').classList.toggle('active');
        }

        function handlelogo(){
            window.location= "/";
        }

        return(
            <div>
                <div className="topnav">
                    <img className='menuimg pointer-cursor' onClick={handleclose} src={menu} />
                    <img src={raiffeisen} alt="logoraiffeisen" id='railogotop' className='railogo pointer-cursor' onClick={handlelogo} />
                </div>
                
            </div>
            
        )
    }
}


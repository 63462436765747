// imports
import './App.css';
import React,{ useEffect, useState} from "react";
import bcrypt from 'bcryptjs/dist/bcrypt';

// main function
function EditBenutzer(){

	// set vars
	const [edit, setEdit] = useState("");
	const [del, setDel] = useState("");
	const [newbenutzername, setnewbenutzername] = useState("");
	const [newanrede, setnewanrede] = useState("");
	const [anrede, setanrede] = useState("");
	const [newvorname, setnewvorname] = useState("");
	const [newnachname, setnewnachname] = useState("");
	const [newemail, setnewemail] = useState("");
	const [newgeburtsdatum, setnewgeburtsdatum] = useState("");
	const [newrolle, setnewrolle] = useState("");
	const [seldepname, setseldepname] = useState("");
	const [seldepname2, setseldepname2] = useState("");
	const [olddepname, setolddepname] = useState("");
	const [rolle,setrolle] = useState([]);
	const [selbenutzername,setselbenutzername] = useState("")
	const [selbenutzername2,setselbenutzername2] = useState("")
	const [benutzername,setbenutzername] = useState([]);
	const [seldepid, setseldepid] = useState("");
	const [selrolle, setselrolle] = useState("");
	const [newpassword, setnewpassword] = useState("");
	const [users,setUser] = useState([]);
	const [password, setpassword] = useState("");
	const [departments,setDepartments] = useState([]);

	// validation vars
	const [emailvalid, setemailvalid] = useState("");
	const [submitted, setSubmitted] = useState("");
	const [valid, setValid] = useState("");
	const [reply, setReply] = useState("");
	const [depid, setdepid] = useState("");
	const [roleid, setroleid] = useState("");
	const [submitted2, setSubmitted2] = useState("");
	const [valid2, setValid2] = useState("");
	const [status, setStatus] = useState("");

	// jwt
	let jwt = document.cookie.split('; ').reduce((prev, current) => {
		const [name, ...value] = current.split('=');
		prev[name] = value.join('=');
		return prev;
	}, {});

	// check email
	function validateEmail(input) {

		// check if mail matches regex
		var validRegex = /^([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)*$/;
		if (input.match(validRegex)) {setemailvalid(true); return true;}
		else {setemailvalid(false); return false;}

	}

	// hashes the password on entering a new password for user
	function createHash(password){

		let hash = bcrypt.genSalt().then(salt => {

			let hash = bcrypt.hash(password, salt).then(hash => {

				return hash;
			});

			return hash;

		});

		return hash;

	}

	// edits a user
	async function editData(){

		// resets validation 
		setValid("");
		setReply("");
		setemailvalid("");
		validateEmail(newemail);

		// checks if fields are filled out
		if(selbenutzername && newbenutzername && newanrede && newvorname && newnachname && newemail && newgeburtsdatum){

			// then checks whether or not the email is valid using the regex
			if(emailvalid){

				setValid(true);

				// sends the data if there is no new password
				if(newpassword == ""){

					var data = {

						"action": "edituser",
						"username": selbenutzername,
						"editedusername": newbenutzername,
						"formofaddress": newanrede,
						"firstname": newvorname,
						"lastname": newnachname,
						"email": newemail,
						"birthdate": newgeburtsdatum,
						"password": password,
						"fk_role": await getRoleID(),
						"fk_dep": await getDepID(),
						"jwt": jwt.jwt

					}

				}
				// sends the data and creates a new hash for the entered new password
				else {

					var data = {

						"action": "edituser",
						"username": selbenutzername,
						"editedusername": newbenutzername,
						"formofaddress": newanrede,
						"firstname": newvorname,
						"lastname": newnachname,
						"email": newemail,
						"birthdate": newgeburtsdatum,
						"password": await createHash(newpassword),
						"fk_role": await getRoleID(),
						"fk_dep": await getDepID(),
						"jwt": jwt.jwt

					}

				}

				fetch("https://api.rs.digitalnatives.ch/",{

					method: "POST",
					headers:{
						'Accept':'application/json',
					},
					'Content-Type':'application/json',
					body: JSON.stringify(data)

				}).then((resp)=>{

					resp.json().then((results)=>{

						setReply(results.key);
						setStatus(results.status);

					});

				});

				resetFields();

			}

		}
		else {setValid(false);}

	}

	// get the name of the selected department
	function getDepName(selecteddepid){

		var data = {

			"action": "getdepname",
			"depid": selecteddepid,
			"jwt": jwt.jwt

		}

		let name = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then(async(response)=>{

			let name = await response.json().then((results)=>{

				return results.key[0].abteilungsname;

			});

			return name;

		});

		return name;

	}

	// get the id of a department using its name
	function getDepID(){

		var data = {

			"action": "getdepid",
			"depname": seldepname,
			"jwt": jwt.jwt

		}

		let depid = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			let depid = response.json().then((results)=>{

				try {

					let depid = results.key[0].id_abteilung;
					return depid;

				}
				catch {

					return false;

				}

			});

			return depid;

		});

		return depid;

	}

	// get all departments
	function getDeps(){

		var data = {

			"action": "getdeps",
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then((results)=>{

				setDepartments(results.key);

			});

		});

	}

	// get the role name of an id
	function getRoleName(selectedroleid){

		var data = {

			"action": "getrolename",
			"roleid": selectedroleid,
			"jwt": jwt.jwt

		}

		let name = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then(async (response)=>{

			let name = await response.json().then((results)=>{

				return results.key[0].rollenname;

			});

			return name;

		});

		return name;

	}

	// get all roles
	function getRoles(){

		var data = {

			"action": "getroles",
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then((results)=>{

				setrolle(results.key);

			});

		});

	}

	// get id of role using its name
	function getRoleID(){

		var data = {

			"action": "getroleid",
			"rolename": selrolle,
			"jwt": jwt.jwt

		}

		let roleid = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			let roleid = response.json().then((results)=>{

				try {

					let roleid = results.key[0].id_rolle;
					return roleid;

				}
				catch {

					return false;

				}

			});

			return roleid;

		});

		return roleid;

	}

	// gets some information from a user and inputs them into the fields 
	function getUserInfo(username){

		var data = {

			"action": "getuserinfo",
			"username": username,
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then(async (results)=>{

				setnewvorname(results.key[0].vorname);
				setnewnachname(results.key[0].nachname);
				setnewemail(results.key[0].email);
				setnewgeburtsdatum(results.key[0].geburtsdatum);
				setpassword(results.key[0].passwort);
				setnewanrede(results.key[0].anrede);
				setdepid(results.key[0].fk_abteilung);
				setroleid(results.key[0].fk_rolle);
				setseldepname(await getDepName(results.key[0].fk_abteilung));
				setselrolle(await getRoleName(results.key[0].fk_rolle));

			});

		});

	}

	// gets all users
	function getUsers(){
		var data = {
			"action": "getusernames",
			"jwt": jwt.jwt
		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

			}).then((response)=>{

			response.json().then((results)=>{

				setbenutzername(results.key);

			});
		});
	}

	// resets the data
	function resetFields(){

		setselbenutzername("");
		setnewbenutzername("");
		setseldepname("");
		setselrolle("");
		setnewanrede("");
		setnewvorname("");
		setnewnachname("");
		setnewemail("");
		setnewpassword("");
		setnewgeburtsdatum("");

	}

	// deletes the user
	function delUser(){

		setValid2("");
		setSubmitted(false);
		setStatus("");

		if(selbenutzername2){

			setValid2(true);

			let data = {

				"action": "remuser",
				"username": selbenutzername2,
				"jwt": jwt.jwt

			}

			fetch("https://api.rs.digitalnatives.ch/",{

				method: "POST",
				headers:{
					'Accept':'application/json',
					'Content-Type':'application/json',
				},
				body: JSON.stringify(data)

			}).then((resp)=>{

				resp.json().then((results)=>{

					setReply(results.key);
					setStatus(results.status);

				});

			});

		}

	}

	// run when loaded
	useEffect(()=>{getUsers(); getDeps(); getRoles(); getDepName(depid); getRoleName(roleid); getUserInfo("--"); editData(); delUser();}, []);

	// jsx
	return(

		<div className="add-xyz">
			<div className='top-content'>
				<h1>Benutzer bearbeiten</h1>
			</div>
		  <div className='contentform'>

			<form>
				<label>Benutzer auswählen</label><br></br>
				<select onClick={getUsers} value={selbenutzername} onChange={(e)=>{setselbenutzername(e.target.value); setnewbenutzername(e.target.value); getUserInfo(e.target.value);} }>
						<option value="" disabled selected> - Benutzer auswählen - </option>
							{
								benutzername.map((item)=>
								<option value={item.username} onChange={(e)=>{setbenutzername(e.target.value)} }>{item.username}</option>
								)
							}
					</select><br></br><br />

				<label>Neue Benutzerdaten</label><br></br>

						<input type="text" name="benutzername" maxLength="16" value={newbenutzername} onChange={(e)=>{setnewbenutzername(e.target.value)} } placeholder="Benutzername" required="true"/>
							{ submitted &! newbenutzername &! reply ? <span className="not-so-great-success">Wählen Sie bitte ein Benutzer aus</span> : null }
							<br /><br />
				<select onClick={getDeps} value={seldepname} onChange={(e)=>{setseldepname(e.target.value); getDepID();} }>
					<option value="" disabled selected> - Abteilung auswählen - </option>

					{
						departments.map((item)=>
						<option value={item.abteilungsname} onChange={(e)=>{setDepartments(e.target.value)} }>{item.abteilungsname}</option>
						)
					}
				 </select><br></br>
				 { submitted &! seldepname &! reply ? <span className="not-so-great-success">Wählen Sie bitte eine Abteilung</span> : null }<br></br>

				  <select onClick={getRoles} value={selrolle} onChange={(e)=>{setselrolle(e.target.value); getRoleID();} }>
					<option value="" disabled selected> - Rolle auswählen - </option>
					{
						rolle.map((item)=>
						<option value={item.rollenname} onChange={(e)=>{setrolle(e.target.value)} }>{item.rollenname}</option>
						)
					}
				 </select><br></br>
				 { submitted &! selrolle &! reply ? <span className="not-so-great-success">Wählen Sie bitte eine Rolle aus</span> : null }<br></br>
				  <select value={newanrede} onChange={(e)=>{setnewanrede(e.target.value)} }>
						<option value="" disabled selected> - Geschlecht auswählen - </option>
						<option value="Herr">Herr</option>
						<option value="Frau">Frau</option>
					</select><br></br>
					{ submitted &! newanrede &! reply ? <span className="not-so-great-success">Wählen Sie bitte eine Anrede</span> : null }<br></br>
					  <input type="text" name="vorname" maxLength="20" value={newvorname} onChange={(e)=>{setnewvorname(e.target.value)} }placeholder="Vorname"/><br></br>
					  { submitted &! newvorname &! reply ? <span className="not-so-great-success">Wählen Sie bitte einen Vorname</span> : null }<br></br>
					  <input type="text" name="nachname" maxLength="20" value={newnachname} onChange={(e)=>{setnewnachname(e.target.value)} }placeholder="Nachname"/><br></br>
					  { submitted &! newnachname &! reply ? <span className="not-so-great-success">Wählen Sie bitte einen Nachnamen</span> : null }<br></br>
					  <input type="text" maxLength="45" name="email" value={newemail} onChange={(e)=>{setnewemail(e.target.value); validateEmail(e.target.value)} }placeholder="Email"/><br></br>
					  { submitted &! newemail &! reply ? <span className="not-so-great-success">Geben Sie bitte eine E-Mail ein</span> : null }
					  { submitted &! emailvalid && newemail ? <span className="not-so-great-success">Diese Mail ist nicht gültig</span> : null }<br></br>
						<input type="password" maxLength="72" name="password" value={newpassword} onChange={(e)=>{setnewpassword(e.target.value)} }placeholder="Password"/><br></br><br />

						<label>Geburtsdatum</label><br />
					  <input type="date" name="geburtsdatum"value={newgeburtsdatum} onChange={(e)=>{setnewgeburtsdatum(e.target.value)} }/><br></br>
					  { submitted &! newgeburtsdatum &! reply ? <span className="not-so-great-success">Wählen Sie bitte ein Geburtsdatum</span> : null }<br></br><br></br>
					  <button type="button" onClick={()=>{editData();setSubmitted(true);}}>Bearbeiten</button>
					  <br></br>
						{ submitted && valid && (reply == "What were you even trying to do, cousin?") ? <span className="not-so-great-success">{reply}</span> : null }
						{ submitted && valid &! (reply == "What were you even trying to do, cousin?") ? <span className="great-success">{reply}</span> : null }
					<br></br><br></br>
			</form>
			</div>
			<div className='top-content'>
				<h1>Benutzer löschen</h1>
			</div>
			<div className='contentform'>
				<form>
				<label>Benutzer auswählen</label><br></br>
				<select onClick={getUsers} value={selbenutzername2} onChange={(e)=>{setselbenutzername2(e.target.value);} }>
					<option value="" disabled selected> - Benutzer auswählen - </option>
						{
							benutzername.map((item)=>
							<option value={item.username} onChange={(e)=>{setselbenutzername2(e.target.value)} }>{item.username}</option>
							)
						}
					</select><br></br>
					{ submitted2 &! selbenutzername2 &! reply ? <span className="not-so-great-success">Wählen Sie bitte einen Benutzer aus</span> : null }
					<br></br><br></br>

					<button type="button" onClick={()=>{delUser(); getUsers(); setselbenutzername2(""); setSubmitted2(true);}}>Löschen</button><br></br>

				{ submitted2 && valid2 && (status == 400) ? <span className="not-so-great-success">{reply}</span> : null }
				{ submitted2 && valid2 && (status == 200) ?<span className='great-success'>{reply}</span> : null }
				{ submitted && valid2 && (reply == "What were you even trying to do, cousin?") ? <span className="not-so-great-success">{reply}</span> : null }
				{ submitted && valid2 &! (reply == "What were you even trying to do, cousin?") ? <span className="great-success">{reply}</span> : null }
				</form>
				<br />

				<br />
			</div>
		</div>

	)

}

export default EditBenutzer;
// imports
import React, { useState, useEffect, createRef } from "react";
import './App.css';
import FullCalendar ,{EventContentArg} from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import localtestevents from './fixtures/events.json';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import momentPlugin from '@fullcalendar/moment'
import moment from 'moment';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Button, Box, TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

// main function
function Planner(){

	// set variables
	const [title, settitle]=useState("");
	const [startdate, setstartdate]=useState("");
	const [enddate, setenddate]=useState("");
	const [description, setdescription]=useState("");

	// jwt
	let jwt = document.cookie.split('; ').reduce((prev, current) => {
		const [name, ...value] = current.split('=');
		prev[name] = value.join('=');
		return prev;
	}, {});

	const calenderRef = createRef();

	// function to delete a stage
	function remStage(id){

		var data = {

			"action": "remstage",
			"stage_id": id,
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{
		
			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)
		
		}).then((response)=>{
		
			response.json().then((results)=>{

				console.log(results);
		
			}); 

		});

	}

	// what to do if an event is clicked
	const handleEventClick = (clickInfo)=>{

		var id = (clickInfo.event.id);

		// pop up
		confirmAlert({

			customUI: ({ onClose }) => {

				let title = ` ${clickInfo.event.title}`;
				let description = ` ${clickInfo.event.extendedProps.description}`;
				let start = ` ${clickInfo.event.start}.`;
				let momstart = moment(start).format('DD.MM.YYYY');
				let end = ` ${clickInfo.event.extendedProps.displayend}`;
				let momend = moment(end).format('DD.MM.YYYY');
				let abteilung = ` ${clickInfo.event.extendedProps.abteilung}`;
				let openspots = ` ${clickInfo.event.extendedProps.offenestellen}`;
				
				// jsx for pop up
				return (

				  <div className='custom-ui'>
					<div className="popupconfirm-title">
						<h1>{title}</h1>
						<p>Details for{title}</p><br></br>

						<p><b>Starttermin:</b> {momstart}</p>
						<p><b>Endtermin:</b> {momend}</p>
						<p><b>Abteilung:</b> {abteilung}</p>
						<p><b>Offene Stellen:</b> {openspots}</p>
						<p><b>Beschreibung:</b> {description}</p>
						<br></br>
					   

					</div>
					<div className="popupconfirm-buttons">
						<button id="delete" onClick={() => {
							clickInfo.event.remove();
							remStage(id);
							onClose();
						}}
						>Löschen
						</button>
						<button id="edit" onClick={() => {
								routeChange2(clickInfo.event.id)
								onClose();
							}}
						>Verwalten
						</button>
						<button id="cancel" onClick={onClose}>Abbrechen</button>
					</div>
				  </div>

				);

			}

		});
		
	}

	// define redirects via react router
	let navigate = useNavigate(); 
	const routeChange = () =>{

		let path = '/user/stageerstellen'; 
		navigate(path);

	}
	
	const routeChange2 = (id) =>{ 

		let path = '/user/stageverwalten?id='+ id; 
		navigate(path);

	}

 
	// jsx
	return (

		<div className="add-calendar">
			<div className="calendar">
				<FullCalendar 
					ref={calenderRef}
					plugins={[ dayGridPlugin, timeGridPlugin, listPlugin, momentPlugin]} 
					initialView="dayGridMonth"
					editable="false"
					selectable="false"
					deselectable="false"
					events="https://api.rs.digitalnatives.ch/stagescalendar.php"
					eventClick={handleEventClick}
					
					customButtons={{

						newStageBtn:{
							text: "+",
							click(){routeChange()},
						}, 

						myTimeDayBtn:{
							text: "timeDay",
							click() {
								const calender = calenderRef.current;

								if (calender) {
									const calenderApi = calender.getApi();

									calenderApi.changeView("timeGridDay")
								}
							},
						},

											 

						myTimeWeekBtn:{
							text: "timeWeek",
							click() {
								const calender = calenderRef.current;

								if (calender) {
									const calenderApi = calender.getApi();

									calenderApi.changeView("timeGridWeek");
								}
							},
						},
					}}
					
					headerToolbar={{
						left: "prev,next today newStageBtn",
						center: "title",
						right: "dayGridWeek,dayGridMonth,listYear"
					}}
				/>
			</div>
		</div>
		
	);
   
}

export default Planner;
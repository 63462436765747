import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import Home from '../home';
import iconhome from '../img/iconhome.png';
import iconabteilung from '../img/iconabteilung.png'
import iconabteilung2 from '../img/iconabteilung2.png'
import iconbenutzer from '../img/iconbenutzer.png'
import iconcalendar from '../img/iconcalendar.png'
import iconstage from '../img/iconstage.png'
import close from '../img/close.png'


export function Navsidebar() {

    const [userrole, setuserrole] = useState("");

    async function getUserRole(){

        let jwt = document.cookie.split('; ').reduce((prev, current) => {
            const [name, ...value] = current.split('=');
            prev[name] = value.join('=');
            return prev;
        }, {});

        var data = {
            "action": "getuserrole",
            "jwt": jwt.jwt
        }

        var role = fetch("https://api.rs.digitalnatives.ch/",{

            method: "POST",
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
            },
            body: JSON.stringify(data)

        }).then(async(response)=>{

            let roleresult = response.json().then((results)=>{

                return results.key;

            });

            return await roleresult;

        });

        return role;

    }

    getUserRole().then(role => setuserrole(role));

    function logout(){
        document.cookie = 'jwt=; path=/user; max-age=0';
        document.cookie = 'jwt=; path=/; max-age=0';
        window.location = "/login";
    }
    


    function handleclose(){
        document.getElementById('yeetfield').classList.remove('active');
        document.getElementById('sidenav').classList.remove('active');
    }

    return(
        <div className="Navsidebar">
            <div className="sidenav" id='sidenav'>
            
                <Link to='/user/home'><a><img className="icons"src={iconhome} alt="iconhome"/>Home</a></Link>

                { userrole >= 4 ? <Link to='/user/addabteilung'><a><img className="icons"src={iconabteilung} alt="iconabteilung"/>Abteilung hinzufügen</a></Link>: null}

                { userrole >= 4 ? <Link to='/user/editabteilung'><a><img className="icons"src={iconabteilung2} alt="iconabteilung2"/>Abteilung verwalten</a></Link>: null}

                { userrole >= 4 ? <Link to='/user/addbenutzer'><a><img className="icons"src={iconbenutzer} alt="iconbenutzer"/>Benutzer hinzufügen</a></Link>: null}

                { userrole >= 4 ? <Link to='/user/editbenutzer'><a><img className="icons"src={iconbenutzer} alt="iconbenutzer"/>Benutzer verwalten</a></Link>: null}

                { userrole >= 3 ? <Link to='/user/stageerstellen'><a><img className="icons"src={iconcalendar} alt="iconbenutzer"/>Stage hinzufügen</a></Link>: null}

                { userrole >= 3 ? <Link to='/user/stageverwalten'><a><img className="icons"src={iconcalendar} alt="iconbenutzer"/>Stage verwalten</a></Link>: null}
                
                { userrole >= 3 ? <Link to='/user/offenestageanfragen'><a><img className="icons"src={iconstage} alt="iconbenutzer"/>Offene Stageanfragen</a></Link>: null}

                { userrole <= 2 ? <Link to='/user/meineanfragen'><a><img className="icons"src={iconstage} alt="iconbenutzer"/>Meine Stageanfragen</a></Link>: null}

                { userrole >= 1 ? <Link to='/user/planner'><a><img className="icons"src={iconcalendar} alt="iconbenutzer"/>Kalender</a></Link>: null}


                <br />

                <a onClick={logout}>
                    <a><img className="icons"src={iconcalendar} alt="iconbenutzer"/>
                    Logout</a>
                </a>
                
            </div>

            <div className='yeetfield' id='yeetfield' onClick={handleclose}/>
            <div className='yeetfield' id='yeetfield' onClick={handleclose}/>
            <div className='spaghettidoor' id='spaghettidoor' />
        </div>
        
    )
}

// imports
import './App.css';
import {Header} from './components/header';
import React,{ useEffect, useState} from "react";
import { Button, Box, TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import bcrypt from 'bcryptjs/dist/bcrypt';

// main function
function Addbenutzer(){

	// variables
	const [benutzername, setbenutzername]=useState("");
	const [anrede, setanrede]=useState("");
	const [vorname, setvorname]=useState("");
	const [nachname, setnachname]=useState("");
	const [email, setemail]=useState("");
	const [emailvalid, setemailvalid]=useState("");
	const [geburtsdatum, setgeburtsdatum]=useState("");
	const [selrolle, setselrolle]=useState("");
	const [password, setpassword]=useState("");
	const [seldepname, setseldepname]=useState("");
	const [seldepid, setseldepid]=useState("");
	const [rolle,setrolle]=useState([]);
	const [departments,setDepartments]=useState([]);

	// variables validation
	const [submitted, setSubmitted]=useState("");
	const [valid, setValid]=useState("");
	const [reply, setReply]=useState("");
	const [depid, setdepid]=useState("");

	// credentials
	let jwt = document.cookie.split('; ').reduce((prev, current) => {
		const [name, ...value] = current.split('=');
		prev[name] = value.join('=');
		return prev;
	}, {});

	// check email
	function validateEmail(input) {
		var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

		if (input.match(validRegex)) {setemailvalid(true); return true;}
		else {setemailvalid(false); return false;}
	}

	// post the data
	async function saveData(){

		// reset validation
		setValid("");
		setReply("");
		setemailvalid("");
		setSubmitted(true);

		// check if fields are filled
		if(benutzername && anrede && vorname && nachname && email && geburtsdatum && selrolle && seldepname && password){

			// check if email is valid
			if(emailvalid){

				// fetch, if data is valid
				setValid(true);
				let data = {

					"action": "newuser",
					"username": benutzername,
					"formofaddress": anrede,
					"firstname": vorname,
					"lastname": nachname,
					"email": email,
					"birthdate": geburtsdatum,
					"password": await createHash(password),
					"fk_role": await getRoleID(),
					"fk_dep": await getDepID(),
					"jwt": jwt.jwt

				}

				fetch("https://api.rs.digitalnatives.ch/",{

					method: "POST",
					headers:{
						'Accept':'application/json',
						'Content-Type':'application/json',
					},
					body: JSON.stringify(data)

				}).then((resp)=>{

					resp.json().then((
						results
						)=>{

						setReply(
							results.key
							);

					});
				});

				// resets the fields
				resetFields();

			}

		}
		else {

			setValid(false);

		}

	}

	// fetch departments
	function getDeps(){

		var data = {

			"action": "getdeps",
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then((results)=>{

				setDepartments(results.key);

			});

		});

	}

	// fetch roles
	function getRoles(){

		var data = {

			"action": "getroles",
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

			}).then((response)=>{

			response.json().then((results)=>{

				setrolle(results.key);

			});

		});

	}

	// fetch id of department
	function getDepID(){

		var data = {

			"action": "getdepid",
			"depname": seldepname,
			"jwt": jwt.jwt

		}

		let depid = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

			}).then((response)=>{

			let depid = response.json().then((results)=>{

				try {

					let depid = results.key[0].id_abteilung;
					console.log(depid);
					return depid;

				}
				catch {

					return false;

				}

			});

			return depid;

		});

		return depid;

	}

	// fetch id for role
	function getRoleID(){
		var data = {
			"action": "getroleid",
			"rolename": selrolle,
			"jwt": jwt.jwt
		}

		let roleid = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			let roleid = response.json().then((results)=>{

				try {

					let roleid = results.key[0].id_rolle;
					console.log(roleid);
					return roleid;

				}
				catch {

					return false;

				}

			});

			return roleid;

		});

		return roleid;
	}

	// hash the password and return the hash
	function createHash(password){

		let hash = bcrypt.genSalt().then(salt => {

			let hash = bcrypt.hash(password, salt).then(hash => {

				return hash;

			});

			return hash;
		});

		return hash;
	}

	// resets the variables
	function resetFields(){

		setbenutzername("");
		setanrede("");
		setvorname("");
		setnachname("");
		setemail("");
		setpassword("");
		setgeburtsdatum("");
		setseldepname("");
		setselrolle("");
		setemailvalid("");

	}

	// get data
	useEffect(()=>{getDeps(); getRoles();}, []);

	// jsx
	return (

		<div className="add-xyz">
			<div className="top-content">
				<h1>Neuen Benutzer hinzufügen</h1>
			</div>
			<div className='contentform'>
				<form>
					<input type="text" maxLength="16" name="benutzername" value={benutzername} onChange={(e)=>{setbenutzername(e.target.value)} } placeholder="Benutzername" required="true" /><br></br>
					{ submitted &! benutzername &! reply ? <span className="not-so-great-success">Geben Sie bitte einen Benutzernamen ein</span> : null }<br></br>

					<select onClick={getDeps} value={seldepname} onChange={(e)=>{setseldepname(e.target.value); getDepID();} }>
						<option value="" disabled selected> - Abteilung auswählen - </option>

						{
							departments.map((item)=>
							<option value={item.abteilungsname} onChange={(e)=>{setDepartments(e.target.value)} }>{item.abteilungsname}</option>
							)
						}
					 </select><br></br>
					{ submitted &! seldepname &! reply ? <span className="not-so-great-success">Wählen Sie bitte eine Abteilung</span> : null }<br></br>

					 <select onClick={getRoles} value={selrolle} onChange={(e)=>{setselrolle(e.target.value); getRoleID();} }>
						<option value="" disabled selected> - Rolle auswählen - </option>
						{
							rolle.map((item)=>
							<option value={item.rollenname} onChange={(e)=>{setrolle(e.target.value)} }>{item.rollenname}</option>
							)
						}
					 </select><br></br>
					{ submitted &! selrolle &! reply ? <span className="not-so-great-success">Wählen Sie bitte eine Rolle aus</span> : null }<br></br>

						<select value={anrede} onChange={(e)=>{setanrede(e.target.value)} }>
							<option value="" disabled selected> - Anrede auswählen - </option>
							<option value="Herr">Herr</option>
							<option value="Frau">Frau</option>
						</select><br></br>
					{ submitted &! anrede &! reply ? <span className="not-so-great-success">Wählen Sie bitte eine Anrede</span> : null }<br></br>

					<input type="text" maxLength="20" name="vorname"value={vorname} onChange={(e)=>{setvorname(e.target.value)} }placeholder="Vorname"/><br></br>
					{ submitted &! vorname &! reply ? <span className="not-so-great-success">Geben Sie bitte einen Vornamen ein</span> : null }<br></br>

					<input type="text" maxLength="20" name="nachname"value={nachname} onChange={(e)=>{setnachname(e.target.value)} }placeholder="Nachname"/><br></br>
					{ submitted &! nachname &! reply ? <span className="not-so-great-success">Geben Sie bitte einen Nachnamen ein</span> : null }<br></br>

					<input type="text" maxLength="45" name="email" value={email} onChange={(e)=>{setemail(e.target.value); validateEmail(e.target.value)} }placeholder="Email"/><br></br>
					{ submitted &! email &! reply ? <span className="not-so-great-success">Geben Sie bitte eine E-Mail ein</span> : null }
					{ submitted &! emailvalid && email ? <span className="not-so-great-success">Diese Mail ist nicht gültig</span> : null }<br></br>

					<input type="password" maxLength="72" name="password" value={password} onChange={(e)=>{setpassword(e.target.value)} }placeholder="Password"/><br></br>
					{ submitted &! password &! reply ? <span className="not-so-great-success">Geben Sie bitte ein Passwort ein</span> : null }<br></br>

					<label>Geburtsdatum</label><br />
					<input type="date" name="geburtsdatum" placeholder="dd-mm-yyyy" value={geburtsdatum} onChange={(e)=>{setgeburtsdatum(e.target.value)} }/><br></br>
					{ submitted &! geburtsdatum &! reply ? <span className="not-so-great-success">Wählen Sie bitte ein Geburtsdatum</span> : null }<br></br>

					<button type="button" onClick={()=>{saveData();}}>Hinzufügen</button><br></br>

					{ submitted && valid && (reply == "Something went wrong, please try again. Does the user exist already?") ? <span className="not-so-great-success">{reply}</span> : null }
					{ submitted && valid &! (reply == "Something went wrong, please try again. Does the user exist already?") ? <span className="great-success">{reply}</span> : null }<br></br><br></br><br></br><br></br>

				</form>
			</div>
		</div>
	)
}

export default Addbenutzer;












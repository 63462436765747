import React, {useState} from 'react';
import {Link} from 'react-router-dom';

//prompts user to get out of the console
console.warn("Get out son, don't even try anything funny cousin");

export function Login() {

	//usestates
	const [values, setValues] = useState({
		username: "",
		password: "",
	});
	const [submitted, setSubmitted] = useState(false);
	const [valid, setValid] = useState(false);
	const [pwvalid, setPwValid] = useState(false);
	const [credentialsWrong, setCredentialsWrong] = useState(false);
	const [savedjwt, setjwt] = useState("");

	//what to do if inputs are changed (set states)
	const handleUsernameInputChange = (event) => {
		setValues({...values, username: event.target.value});
	}
	const handlePasswordInputChange = (event) => {
		setValues({...values, password: event.target.value});
	}

	//what to do when submit is clicked
	const handleSubmit = (event) => {

		event.preventDefault();
		if(values.username && values.password){

			//resets the states each time data is submitted
			setValid(true);
			setPwValid(false);
			setCredentialsWrong(false);

			//sets variables
			const username = event.target.username.value;
			const password = event.target.password.value;

			//encrypts userdata

			//sets data to send via http
			var data = {
				"action": "login",
				"username": username,
				"password": password

			}

			//sends the request
			let pw = fetch("https://api.rs.digitalnatives.ch/",{

				method: "POST",
				headers:{
					'Accept':'application/json',
					'Content-Type':'application/json',
				},
				body: JSON.stringify(data)

			}).then((response)=>{

				//converts json to objects and returns the hashed password from the db
				response.json().then((results)=>{

					//if it fails, the username or password was incorrect
					if(results.status === 200){

						// remove old cookies
						document.cookie = 'jwt=; path=/user; max-age=0';
						document.cookie = 'jwt=; path=/; max-age=0';

						// sets new cookie and allows redirect
						document.cookie = "jwt=" + results.key;
						setCredentialsWrong(false);
						setPwValid(true);
						window.location = "/user";

					}
					else {

						// credentials wrong
						setCredentialsWrong(true);
						return false;

					}

				});

			});

		}

		//sets the submitted switch to true
		setSubmitted(true);

	}

	//jsx
	return(

		<div>
		<div className="bg2"></div>
		<div>

			<div id="loginform">

				<h1>Login</h1>
				<h2 id="letusknow">
				Hier kannst Du Dich in dein Konto einloggen, um Deine Stagen zu verwalten oder beantragen.<br />

				Hast Du noch keinen Account oder hast Dein Passwort vergessen? <Link className="link2" to="/contact">Kontaktiere uns.</Link>
				</h2>

				<div id="formdiv">

					<form className="formcontact" onSubmit={handleSubmit}>

						<input onChange={handleUsernameInputChange} value={values.username} name="username" type="text" placeholder="Benutzername"/><br></br>
						{submitted && !values.username  ? <span className="form-prompt">Bitte gib Deinen Benutzernamen ein</span> : null}<br></br>

						<input onChange={handlePasswordInputChange} value={values.password}name="password" type="password" placeholder="Passwort" autoComplete="on" /><br></br>
						{submitted && !values.password  ? <span className="form-prompt">Bitte gib Dein Passwort ein</span> : null}<br></br>

						<button className="submitbtn" type="submit">Weiter</button><br /><br />

						{submitted && valid &! pwvalid &! credentialsWrong ? <div className="pending-message" id="checking">Überprüfen der Anmeldedaten...</div> : null}
						{submitted && valid && pwvalid &! credentialsWrong ? <div className="success-message" id="checking">Login erfolgreich</div> : null}
						{submitted && valid &! pwvalid && credentialsWrong ? <div className="failure-message" id="checking">Login fehlgeschlagen, überprüfe Deine Daten</div> : null}

					</form>

				</div>

			</div>

		</div>
		</div>

	)

}

export default Login;

// imports
import React from "react";
import './App.css';
import {Header} from './components/header';
import { useState , useEffect} from 'react';
import { data } from "autoprefixer";
import { Link } from 'react-router-dom';
import moment from 'moment';

// main function
function Userhome(){

	// set variables
	const [currentuser, setcurrentuser] = useState("");
	const [firstname, setfirstname] = useState("");
	const [lastname, setlastname] = useState("");
	const [foa, setfoa] = useState("");
	const [email, setemail] = useState("");
	const [bday, setbday] = useState("");
	const [uuid, setuuid] = useState("");

	// gets the current user from jwt
	async function getCurrentUser(){

		let jwt = document.cookie.split('; ').reduce((prev, current) => {
			const [name, ...value] = current.split('=');
			prev[name] = value.join('=');
			return prev;
		}, {});

		var data = {

			"action": "gettokenuser",
			"jwt": jwt.jwt

		}

		var yes = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then(async(response)=>{

			let currentuser = response.json().then((results)=>{

				setcurrentuser(results.key);
				return results.key;

			});

			return await currentuser;

		});

		return await yes;

	}

	// gets user info from jwt
	async function getNamesOfUser(){

		let jwt = document.cookie.split('; ').reduce((prev, current) => {
			const [name, ...value] = current.split('=');
			prev[name] = value.join('=');
			return prev;
		}, {});

		var data = {

			"action": "getuserinfo",
			"username": await getCurrentUser(),
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then((results)=>{

				setfirstname(results.key[0].vorname);
				setlastname(results.key[0].nachname);
				setfoa(results.key[0].anrede);
				setemail(results.key[0].email);
				setbday(results.key[0].geburtsdatum);
				setuuid(results.key[0].id_user);
				console.log(results);

			});

		});

	}

	// formats the birthday using moment
	let mombday = moment(bday).format('DD.MM.YYYY')

	// executes the function to get user info
	getNamesOfUser();

	// jsx
	return (

		<div className="add-xyz">

			<h1>Home</h1>

			{
				true ?
				<h3>Willkommen zu Hause, {firstname} {lastname}</h3>
				: null
			}

			{
				true ?
				<span>Voller Name: {foa} {firstname} {lastname}</span>
				: null
			}

			{
				true ?
				<span>Benutzername: {currentuser}</span>
				: null
			}

			{
				true ?
				<span>Geburtsdatum: {mombday}</span>
				: null
			}

			{
				true ?
				<span>E-Mail: {email}</span>
				: null
			}

			{
				true ?
				<span>User-ID: {uuid}</span>
				: null
			}

			<Link to="/user/planner">Zum Kalender</Link>

		</div>

	)

}

export default Userhome;
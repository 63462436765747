// imports
import './App.css';
import {Header} from './components/header';
import React,{ useEffect, useState} from "react";
import { Button, Box, TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { SketchPicker } from "react-color";

// main function
function Addabteilung(){

	// set vars
	const [stage, setstage]=useState("");
	const [verantwortlicher, setverantwortlicher]=useState("");
	const [startdate, setstartdate]=useState("");
	const [enddate, setenddate]=useState("");
	const [aktiv, setaktiv ]=useState("1");
	const [depid, setdepid]=useState("");
	const [offenestellen, setoffenestellen]=useState("");
	const [description, setdescription]=useState("");
	const [seldepname, setseldepname]=useState("");
	const [seldepid, setseldepid]=useState("");
	const [departments,setDepartments]=useState([]);
	const [bgcolor, setbgcolor] = useState("#fff");

	// jwt
	let jwt = document.cookie.split('; ').reduce((prev, current) => {
		const [name, ...value] = current.split('=');
		prev[name] = value.join('=');
		return prev;
	}, {});

	//validation
	const [submitted, setSubmitted]=useState("");
	const [valid, setValid]=useState("");
	const [reply, setReply]=useState("");
	const [status, setStatus]=useState("");

	// what to do if color picker gets toggled
	function handlecolor(){document.getElementById('color-clicked').classList.toggle('active');}

	// creates the stage
	async function saveData(){

		// resets the validation
		setValid("");
		setStatus("");
		setReply("");
		setSubmitted(true);

		// checks for validity and posts the request
		if(startdate && enddate && offenestellen && aktiv && seldepname && description) {

			setValid(true);
			let data = {

				"action": "newstage",
				"startdate": startdate,
				"enddate": enddate,
				"openspots": offenestellen,
				"active": aktiv,
				"fk_dep": await getDepID(),
				"description": description,
				"bgcolor": bgcolor,
				"jwt": jwt.jwt

			}

			fetch("https://api.rs.digitalnatives.ch/",{

				method: "POST",
				headers:{
					'Accept':'application/json',
					'Content-Type':'application/json',
				},
				body: JSON.stringify(data)

			}).then((resp)=>{

				resp.json().then((results)=>{

					setStatus(results.status);
					setReply(results.key);

				});

			});

			resetFields();

		}
		else {setValid(false);}

	}

	// resets the fields
	function resetFields(){

		setstartdate("");
		setenddate("");
		setoffenestellen("");
		setaktiv("");
		setseldepname("");
		setdescription("");
		console.log("reset");

	}

	// function to get the id of a department
	function getDepID(){

		var data = {

			"action": "getdepid",
			"depname": seldepname,
			"jwt": jwt.jwt

		}

		let depid = fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			let depid = response.json().then((results)=>{

				try {

					let depid = results.key[0].id_abteilung;
					return depid;

				}
				catch {

					return false;

				}

			});

			return depid;

		});

		return depid;
	}

	// gets all departments
	function getDeps(){

		var data = {

			"action": "getdeps",
			"jwt": jwt.jwt

		}

		fetch("https://api.rs.digitalnatives.ch/",{

			method: "POST",
			headers:{
				'Accept':'application/json',
				'Content-Type':'application/json',
			},
			body: JSON.stringify(data)

		}).then((response)=>{

			response.json().then((results)=>{

				setDepartments(results.key);

			});

		});

	}

	// execute on load
	useEffect(()=>{getDeps();}, []);

	// jsx
	return(
		<div className="add-xyz">
			<div className='roster'>
				<div className='top-content'>
					<h1>Neue Stage erstellen</h1>
				</div>
				<div className='contentform'>
					<form>
						<label>Startdatum</label><br></br>
						<input type="date" name="startdate"value={startdate} onChange={(e)=>{setstartdate(e.target.value)} }/><br></br>
						{ submitted &! startdate &! reply ? <span className="not-so-great-success">Wählen Sie bitte ein Startdatum</span> : null }<br></br>
						<label>Enddatum</label><br></br>
						<input type="date" name="enddate"value={enddate} onChange={(e)=>{setenddate(e.target.value)} }/><br></br>
						{ submitted &! enddate &! reply ? <span className="not-so-great-success">Wählen Sie bitte ein Enddatum</span> : null }<br></br>
						<select value={aktiv} onChange={(e)=>{setaktiv(e.target.value)}}>
								<option value={1} selected>Aktiv</option>
								<option value={0}>Inaktiv</option>
						</select><br></br>
						<select onClick={getDeps} value={seldepname} onChange={(e)=>{setseldepname(e.target.value); getDepID();} }>
							<option value="" disabled selected> - Abteilung auswählen - </option>

							{
								departments.map((item)=>
								<option value={item.abteilungsname} onChange={(e)=>{setDepartments(e.target.value)} }>{item.abteilungsname}</option>
								)
							}
						</select><br></br>
						{ submitted &! seldepname &! reply ? <span className="not-so-great-success">Wählen Sie bitte eine Abteilung</span> : null }<br></br>
						<input type="number" min="0" name="offenestellen" value={offenestellen} onChange={(e)=>{setoffenestellen(Math.abs(e.target.value))} }placeholder="offene stellen"/><br></br>
						{ submitted &! offenestellen &! reply ? <span className="not-so-great-success">Wählen Sie bitte die Anzahl offener Stellen</span> : null }<br></br>

						<div className="sketchpicker">
							Farbe (Helle Farben vermeiden, da der Text unsichtbar wird)
							<br />
							<br />

							<div onClick={handlecolor} title="yes" className="circle" style={{background: bgcolor}}></div>


							<div id="color-clicked" className="color-clicked">

								<SketchPicker
								disableAlpha={true}
								onChange={(color) => {
									setbgcolor(color.hex);
								}}
								color={bgcolor}
								/>

							</div>
						</div>

						<br />
						<textarea type="text" value={description} onChange={(e)=>{setdescription(e.target.value)}} placeholder="Beschreibung"></textarea><br></br>
						{ submitted &! description &! reply ? <span className="not-so-great-success">Wählen Sie bitte eine Stagebeschreibung</span> : null }<br></br>
						<button type="button" onClick={()=>{saveData();}}>Hinzufügen</button>
						<br></br>
						{ submitted && valid && (status == 400) ? <span className="not-so-great-success">{reply}</span> : null }
						{ submitted && valid && (status == 200) ? <span className='great-success'>{reply}</span> : null }



					</form>
				</div>
			</div>
			<br /><br />
		</div>

	)
}

export default Addabteilung;
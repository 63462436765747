import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';

export function Loginfirst() {

	//jsx
	return(

		<div>

			<div className="loginfirst">

				<div className="loginfirstbubble">401. <br /> <Link to="/login">Please <u>log in</u> first.</Link></div>

			</div>

		</div>

	)


}

export default Loginfirst;
